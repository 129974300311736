// Icons
import vk from 'images/vk.svg';
import tg from 'images/tg.svg';
import mts from 'images/mts.svg';

// Styles
import styles from './Socials.module.css';

const Socials = () => {
	return (
		<div className={styles.socials}>
			<a rel="noreferrer" target="_blank" href="https://vk.com/mts_label">
				<img src={vk} width="24px" height="24px" alt="vk" />
			</a>
			<a rel="noreferrer" target="_blank" href="https://t.me/mts_label">
				<img src={tg} width="24px" height="24px" alt="tg" />
			</a>
			<a rel="noreferrer" target="_blank" href="http://label.mts.ru/">
				<img src={mts} width="24px" height="24px" alt="mts" />
			</a>
		</div>
	);
};

export default Socials;
